import React from 'react';
import './ConnectTitle.scss'; // Import the SCSS file
import { Divider } from 'antd';
import { useIntl } from 'react-intl';

const ConnectTitle = () => {
  const intl = useIntl();
  return (
    <div className="connection__container">
      <p className="body-text">{intl.formatMessage({ id: 'chidoriConnect.introduction' })}</p>
      <Divider className="connection__divider" />
    </div>
  );
};

export default ConnectTitle;
