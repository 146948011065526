import React from "react";
import "./App.css";
import NavigationLayout from "components/NavigationLayout/NavigationLayout";
import { registerInterceptors } from "utils/http";

function App() {
  registerInterceptors();

  return (
    <div className="App">
      <NavigationLayout />
    </div>
  );
}

export default App;
