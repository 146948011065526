import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import React, { useImperativeHandle, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchableAutoComplete from '../SearchableAutoComplete/SearchableAutoComplete';
import './SparkSafeGuard.scss';
import '../ConfigForm.scss';
import { ColumnsType } from 'antd/es/table';
import { useGetClusterConfig } from 'hooks/cluster';
import { Property } from 'types/cluster.type';

export type SparkCallbackRef = {
  getProperties: () => Record<string, string>;
  convertRecordToProperties: (record: Record<string, string>) => Property[];
};

function SparkSafeGuard(
  { properties }: { properties: Property[] },
  ref: React.Ref<SparkCallbackRef>
) {
  const intl = useIntl();

  const [propertyState, setPropertyState] = useState<Property[]>(properties);

  useImperativeHandle(ref, () => ({
    getProperties: () => {
      return propertyState.reduce((jobPropertiesMap, currentProperty) => {
        const { jobKey, value } = currentProperty;

        if (jobKey && value) {
          jobPropertiesMap[jobKey] = value;
        } else {
          throw new Error(
            intl.formatMessage({ id: 'settings.validationTableError' })
          );
        }

        return jobPropertiesMap;
      }, {} as Record<string, string>);
    },

    convertRecordToProperties: (record: Record<string, string>): Property[] => {
      let jobCounter = 1;

      return Object.entries(record).map(([key, value]) => ({
        key,
        jobKey: (jobCounter++).toString(),
        value,
      }));
    },
  }));

  const sparkPropertiesOptions = [
    { value: 'spark.driver.memory' },
    { value: 'spark.executor.memory' },
    { value: 'spark.driver.cores' },
  ];

  const sparkPropertiesValues = [
    { value: '1g' },
    { value: '2g' },
    { value: '3' },
    { value: '4' },
  ];

  const handleAdd = () => {
    const newData: Property = {
      key: `${propertyState.length + 1}`,
      jobKey: '',
      value: '',
    };
    setPropertyState(prev => [...prev, newData]);
  };

  const handleDelete = (key: string) => {
    setPropertyState(prev => prev.filter(item => item.key !== key));
  };

  const handleInputChange = (
    key: string,
    dataIndex: keyof Property,
    value: string
  ) => {
    setPropertyState(prev =>
      prev.map(item =>
        item.key === key ? { ...item, [dataIndex]: value } : item
      )
    );
  };

  const columns: ColumnsType<Property> = [
    {
      title: intl.formatMessage({ id: 'settings.key' }),
      dataIndex: 'jobKey',
      key: 'jobKey',
      render: (text: string, record) => (
        <SearchableAutoComplete
          text={text}
          placeholder="spark.driver.memory"
          handleInputChange={value =>
            handleInputChange(record.key, 'jobKey', value)
          }
          allOptions={sparkPropertiesOptions}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'settings.value' }),
      dataIndex: 'value',
      key: 'value',
      render: (text: string, record) => (
        <SearchableAutoComplete
          text={text}
          placeholder="3g"
          handleInputChange={value =>
            handleInputChange(record.key, 'value', value)
          }
          allOptions={sparkPropertiesValues}
        />
      ),
    },
    {
      key: 'action',
      width: 50,
      render: (_: any, record) => (
        <Button
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record.key)}
        />
      ),
    },
  ];

  return (
    <section className="config-form__resource">
      <div className="config-form__section-headers config-form__border-bottom">
        <span className="config-form__section-headers-titles">
          <FormattedMessage id="settings.sparkProperties" />
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.sparkPropertiesInfo',
            })}>
            <InfoCircleOutlined />
          </Tooltip>
        </span>
        <Button
          className="safeGuard__add"
          type="default"
          size="small"
          icon={<PlusOutlined />}
          onClick={handleAdd}>
          {intl.formatMessage({ id: 'settings.addProperty' })}
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={propertyState}
        pagination={false}
        rowKey="key"
        size="small"
        className="safeGuard__table"
        scroll={{ y: 230 }}
      />
    </section>
  );
}

export default React.forwardRef(SparkSafeGuard);
