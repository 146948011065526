import axios from 'axios';
import { NumberOfJobsResponse } from 'types/types';
import { API_URL } from 'utils/http';

export async function getRunningJobsMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<NumberOfJobsResponse>(
      `${API_URL}/metrics/number-running-jobs`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching number of running jobs:', error);
    throw error;
  }
}
