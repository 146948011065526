import React from "react";
import "./SparkLogButton.scss";
import { Button, Tooltip } from "antd";
import clsx from "clsx";
import { useIntl } from "react-intl";
import SparkIcon from "assets/icons/spark.svg";
import { JobStatus } from "components/StatusTag/StatusTag";
import { SparkJobStatuses } from "types/types";
import { SizeType } from "antd/es/config-provider/SizeContext";

type SparkLogButtonProps = {
  sparkAppID: string;
  jobStatus: SparkJobStatuses;
  clusterUrl: string;
  showText?: boolean;
  btnSize?: SizeType;
};
function SparkLogButton({
  sparkAppID,
  jobStatus,
  clusterUrl,
  showText = true,
  btnSize = "small",
}: SparkLogButtonProps) {
  const intl = useIntl();
  const tempFalsySparkJobID = !sparkAppID || sparkAppID === "-1";
  const canKill = [JobStatus.Running, JobStatus.In_Queue].includes(jobStatus);

  return (
    <Tooltip
      title={intl.formatMessage({
        id:
          canKill && tempFalsySparkJobID
            ? "actions.sparkHistoryPreparing"
            : "actions.sparkHistory",
      })}
    >
      <a
        href={`${clusterUrl}/history/${sparkAppID}/jobs/`}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "spark-logo-btn__link",
          tempFalsySparkJobID && "disabled-link"
        )}
      >
        <Button
          type="ghost"
          icon={
            <img
              className="spark-logo-btn__history-icon"
              src={SparkIcon}
              alt="spark"
            />
          }
          className="spark-logo-btn__history-button"
          disabled={tempFalsySparkJobID}
          size={btnSize}
        >
          {showText && intl.formatMessage({ id: "actions.sparkHistory" })}
        </Button>
      </a>
    </Tooltip>
  );
}

export default SparkLogButton;
