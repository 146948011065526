import axios from 'axios';
import { SparkDriverMemoryResponse } from 'types/types';
import { API_URL } from 'utils/http';

export async function getSparkMemoryMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<SparkDriverMemoryResponse>(
      `${API_URL}/metrics/memory_usage_limit`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching memory usage and limits:', error);
    throw error;
  }
}
