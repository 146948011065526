import React, { useState } from "react";
import "./SignedURLButton.scss";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, notification } from "antd";
import { AxiosError } from "axios";
import { useGetSignedURL } from "hooks/jobs";
import { useIntl } from "react-intl";
import { SizeType } from "antd/es/config-provider/SizeContext";

type SignedURLButtonProps = {
  tableName: string;
  clusterName: string;
  schema: string;
  jobId: string;
  prefix: string;
  showText?: boolean;
  btnSize?: SizeType;
};
function SignedURLButton({
  tableName,
  clusterName,
  schema,
  jobId,
  prefix,
  showText = true,
  btnSize = "middle",
}: SignedURLButtonProps) {
  const intl = useIntl();
  const [isSignedURLLoading, setIsSignedURLLoading] = useState(false);

  const { mutateAsync: mutateGetJobSignedURL } = useGetSignedURL({
    clusterName,
    tableName,
    schema,
  });

  async function handleGeneratingSignedURL() {
    setIsSignedURLLoading(true);
    try {
      const res = await mutateGetJobSignedURL();
      const signedURL = res.data.signedURL + `&prefix=${prefix}`;
      Modal.info({
        title: intl.formatMessage({ id: "actions.signedURLModal.title" }),
        content: intl
          .formatMessage({ id: "actions.signedURLModal.content" })
          .replace("{jobId}", jobId),
        centered: true,
        width: 620,
        okText: intl.formatMessage({ id: "actions.signedURLModal.okText" }),
        closable: true,
        maskClosable: true,
        async onOk() {
          await navigator.clipboard.writeText(signedURL);
          notification.success({
            message: intl.formatMessage({
              id: "actions.signedURLModal.successMessage",
            }),
          });
        },
      });
    } catch (error: unknown) {
      const err = error as AxiosError;
      console.log(error);
      notification.error({ message: err.message });
    } finally {
      setIsSignedURLLoading(false);
    }
  }

  return (
    <Tooltip title={intl.formatMessage({ id: "actions.signedURL" })}>
      <Button
        type="ghost"
        className="signed-url-btn__wrapper"
        onClick={handleGeneratingSignedURL}
        loading={isSignedURLLoading}
        disabled={!jobId || !clusterName}
        icon={<LinkOutlined />}
        size={btnSize}
      >
        {showText && intl.formatMessage({ id: "actions.signedURL" })}
      </Button>
    </Tooltip>
  );
}

export default SignedURLButton;
