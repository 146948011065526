import React, { useState, useEffect } from 'react';
import './Connections.scss'; // Import the CSS file
import { Avatar, List, Button, Modal, message, Tooltip } from 'antd';
import ChidoriIcon from 'assets/icons/chidori-icon.svg';
import ConnectConfigForm from '../ConnectConfig/ConnectConfigForm';
import { useDisableChidoriConnect, useGetConnectCanRun, useGetConnectConfig } from 'hooks/connect';
import { useIntl } from 'react-intl';
import ShowConfigDetails from '../ConnectConfig/ShowConfigDetails';
import ConnectionSteps from '../ConnectionSteps/ConnectionSteps';




interface ConnectionItem {
  icon: React.ReactNode;
  name: string;
  description: string;
}






const ListItem: React.FC<{ item: ConnectionItem }> = ({ item }) => {
  const [stepsModalVisible, setStepsModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configVisible, setConfigVisible] = useState(false);
  const { data: connectConfig } = useGetConnectConfig();
  const { data: connectCanRun } = useGetConnectCanRun();
  const [isConnected, setIsConnected] = useState<boolean>(connectConfig?.IS_CHIDORI_CONNECT_ENABLED || false);
  const { mutateAsync: mutateDisableChidoriConnect } = useDisableChidoriConnect();
  const intl = useIntl();



  useEffect(() => {
    if (connectConfig) {
      setIsConnected(connectConfig.IS_CHIDORI_CONNECT_ENABLED);
    }
  }, [connectConfig]);

  const changeConfigVisible = () => {
    setConfigVisible(!configVisible);
  };

  const ChangeConnectionVisible = () => {
    setIsModalVisible(!isModalVisible);
  };
  const changeStepsVisible = () => {
    setStepsModalVisible(!stepsModalVisible);
  };

  const handleConnectionToggle = async () => {
    if (isConnected) {
      try {
        await mutateDisableChidoriConnect();
        message.success(intl.formatMessage({ id: "chidoriConnect.successDisconnect" }));

      } catch (error) {
        message.error(intl.formatMessage({ id: "chidoriConnect.failedDisconnect" }));
      }
    } else {
      ChangeConnectionVisible();
    }
  };







  return (
    <>
      <List.Item
        className={`connected-app ${item.name.replace(/\s+/g, '-').toLowerCase()}`}
      >
        <List.Item.Meta
          avatar={<Avatar src={item.icon} size={60} />}
          title={item.name}
          description={
            <>
              {item.description}
              {isConnected || connectConfig?.IS_TRANSITIONING ? (
                <>
                  <br />
                  {intl.formatMessage({ id: 'chidoriConnect.alreadyStarted' })}{' '}
                  <Button disabled={connectConfig?.IS_TRANSITIONING} type="link" className="inline-link-button" onClick={changeConfigVisible}>
                    {intl.formatMessage({ id: 'chidoriConnect.configuration' })}
                  </Button>
                  {', '}
                  {intl.formatMessage({ id: 'chidoriConnect.dealWithIt' })}{' '}
                  <Button disabled={connectConfig?.IS_TRANSITIONING} type="link" className="inline-link-button" onClick={changeStepsVisible}>
                    {intl.formatMessage({ id: 'chidoriConnect.steps' })}
                  </Button>
                </>
              ) : null}
            </>
          }
        />




        <div className="actions-container">
          <Tooltip
            title={
              !connectCanRun?.canRun
                ? intl.formatMessage({
                  id: 'chidoriConnect.upgrade',
                })
                : null
            }>
            <Button
              type="default"
              size="large"
              onClick={handleConnectionToggle}
              loading={connectConfig?.IS_TRANSITIONING}
              disabled={!connectCanRun?.canRun}
              className="action-button"
            >
              <span className="button-text">
                {(isConnected || connectConfig?.IS_TRANSITIONING)
                  ? intl.formatMessage({ id: 'chidoriConnect.disable' })
                  : intl.formatMessage({ id: 'chidoriConnect.enable' })}
              </span>
            </Button>
          </Tooltip>
        </div>

      </List.Item>
      <Modal
        className="connect-wrapper__modal"
        title={intl.formatMessage({ id: "chidoriConnect.configurations" })}
        open={isModalVisible}
        onCancel={ChangeConnectionVisible}
        footer={null}
      >

        <ConnectConfigForm setIsModalVisible={setIsModalVisible} />
      </Modal>
      <Modal
        className="config-wrapper__modal"
        title={intl.formatMessage({ id: "chidoriConnect.configurations" })}
        open={configVisible}
        onCancel={changeConfigVisible}
        footer={null}
      >
        {connectConfig ? (
          <ShowConfigDetails connectConfig={connectConfig} />
        ) : (
          <div>{intl.formatMessage({ id: "chidoriConnect.error.get.configuration" })}</div>
        )}
      </Modal>
      <Modal
        open={stepsModalVisible}
        title={intl.formatMessage({ id: "chidoriConnect.start.connect" })}
        onCancel={changeStepsVisible}
        footer={null}
        width={850}
      >
        <ConnectionSteps />
      </Modal>
    </>
  );
};


const Connections: React.FC = () => {
  const intl = useIntl();
  const connectedApps: ConnectionItem[] = [
    {
      icon: <img src={ChidoriIcon} alt="Chidori Icon" />,
      name: intl.formatMessage({ id: "chidoriConnect.connect" }),
      description: intl.formatMessage({ id: "chidoriConnect.description" }),
    },
  ];
  return (
    <div className="connections-apps">
      <List
        itemLayout="horizontal"
        dataSource={connectedApps}
        renderItem={(item) => <ListItem item={item} />}
      />
    </div>

  );
};

export default Connections;

