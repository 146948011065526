import React from "react";
import "./StatusTag.scss";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  // ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

export enum JobStatus {
  In_Queue = "In_Queue",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
  Cancelled = "Cancelled",
  Timed_Out = "Timed_Out",
}

const statusName = new Map<JobStatus, string>([
  [JobStatus.In_Queue, "In Queue"],
  [JobStatus.Running, "Running"],
  [JobStatus.Succeeded, "Succeeded"],
  [JobStatus.Failed, "Failed"],
  [JobStatus.Cancelled, "Cancelled"],
  [JobStatus.Timed_Out, "Timed Out"],
]);

const statusTagBlueOngoing = ({ spin = false }: { spin: boolean }) => ({
  color: "processing", // blue
  icon: <SyncOutlined spin={spin} />,
});
const statusTagGreenFinishedSuccessfully = () => ({
  color: "success", // green
  icon: <CheckCircleOutlined />,
});
const statusTagRedFinishedWithError = () => ({
  color: "error", // red
  icon: <CloseCircleOutlined />,
});
// const statusTagGreyInfo = () => ({
//   color: "default", // grey
//   icon: <ExclamationCircleOutlined />,
// });

// "In_Queue" | "Running" | "Failed" | "Succeeded" | "Cancelled"
export const statusTag = {
  In_Queue: statusTagBlueOngoing,
  Running: statusTagBlueOngoing,
  Succeeded: statusTagGreenFinishedSuccessfully,
  Cancelled: statusTagRedFinishedWithError,
  Failed: statusTagRedFinishedWithError,
} as const;

function StatusTag({ status }: { status: JobStatus }) {
  return (
    <Tag
      className="status-tag__wrapper"
      icon={(statusTag as any)[status]({ spin: true }).icon}
      color={(statusTag as any)[status]({ spin: true }).color}
      key={status}
    >
      {statusName.get(status)}
    </Tag>
  );
}

export default StatusTag;
