import React, { useState } from 'react';
import './CodeSnippet.scss';
import { message } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; 
import { CheckCircleFilled, CopyOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const CodeSnippet: React.FC = () => {
  const language = 'python';
  const intl = useIntl();
  const code = `# How to connect with Incorta via ChidoriConnect
    
from chidori.connect import ChidoriConnect

config_file_path = "./Auth.txt"
chidori = ChidoriConnect(config_file_path)`;

  const [showCopiedState, setShowCopiedState] = useState(false);

  const handleCopy = () => {
    setShowCopiedState(true);
    setTimeout(() => {
      setShowCopiedState(false);
    }, 3000);
    navigator.clipboard.writeText(code);
    message.success(intl.formatMessage({ id: 'chidoriConnect.message.copy.success' }));
  };

  return (
    <section className="code-snippet__wrapper">
      <SyntaxHighlighter
        language={language}
        style={a11yDark}
        wrapLines
        customStyle={{ cursor: 'pointer' }}>
        {code}
      </SyntaxHighlighter>
      {showCopiedState ? (
        <CheckCircleFilled className="code-snippet__copy-success" />
      ) : (
        <CopyOutlined
          className="code-snippet__copy-btn"
          onClick={handleCopy}
          title="Copy to clipboard"
        />
      )}
    </section>
  );
};

export default CodeSnippet;
