import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import useChidoriRoutes from "hooks/useChidoriRoutes";

function ChidoriRouting({ children }: PropsWithChildren<any>) {
  const { currentAppliedFilters, setCurrentAppliedFilters } =
    useContext(AppContext);
  const { isHomePath } = useChidoriRoutes();
  const { pathname } = useLocation();
  const [currentLocation, setCurrentLocation] = useState<null | string>(
    pathname
  );

  useEffect(() => {
    if (currentLocation !== null && pathname !== currentLocation) {
      setCurrentLocation((prev) => pathname);

      if (isHomePath && Object.keys(currentAppliedFilters).length) {
        setCurrentAppliedFilters({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <>{children}</>;
}

export default ChidoriRouting;
