import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "AppRoutes/AppRoutes";

import AppContextProvider from "contexts/AppContext";
import LocaleProvider from "contexts/LocaleProvider";
import ChidoriRouting from "contexts/ChidroiRouting";
export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <LocaleProvider>
            <ChidoriRouting>
              <AppRoutes />
            </ChidoriRouting>
          </LocaleProvider>
        </AppContextProvider>
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
