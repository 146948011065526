import React from 'react';
import { formatDurationToHMS } from 'utils/date';
import "./DurationCellRenderer.scss"

export default function DurationCellRenderer({
  duration,
}: {
  duration: Duration;
}) {
  return (
    <section className="duration-cell-renderer">
      {formatDurationToHMS({ duration, shorthand: true })}
    </section>
  );
}
