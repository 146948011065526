import React from "react";
import "./SpinnerChidori.scss";
import { Spin } from "antd";
import ChidoriIcon from "assets/icons/chidori-icon.svg";

function SpinnerChidori() {
  return (
    <Spin
      spinning={true}
      indicator={
        <img
          className="chidori__loading-spinner"
          src={ChidoriIcon}
          alt="Chidori Icon"
        />
      }
    />
  );
}

export default SpinnerChidori;
