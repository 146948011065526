import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import ConfigForm from 'components/Settings/ConfigForm/ConfigForm';
import './Settings.scss';
import { useIntl } from 'react-intl';

export default function Settings() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const intl = useIntl();

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  return (
    <div className="settings-wrapper">
      <Button
        className="settings-wrapper__button"
        type="text"
        icon={<SettingOutlined />}
        onClick={() => {
          setIsModalVisible(true);
        }}>
        {intl.formatMessage({ id: 'settings' })}
      </Button>
      <Modal
        className="settings-wrapper__modal"
        title={
          <div className="settings-wrapper__modal-title">
            {intl.formatMessage({ id: 'settings' })}
          </div>
        }
        onCancel={handleCancel}
        open={isModalVisible}
        footer={[]}>
        <ConfigForm
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>
    </div>
  );
}
