import React, { useState } from "react";
import { useGetJobLogs } from "hooks/jobs";
import useSignedURL from "hooks/useSignedURL";
import { useLocation } from "react-router-dom";
import { notification } from "antd";
import { useIntl } from "react-intl";

type useDownloadLogsProps = {
  clusterName: string;
  jobId: string;
};
function useDownloadLogs({ clusterName, jobId }: useDownloadLogsProps) {
  const intl = useIntl();
  const { pathname } = useLocation();
  const tableNameFromURL = pathname.split("/table/")[1];
  const { signature, expires, isSignedURL } = useSignedURL();
  const { mutateAsync: mutateGetJobLogURL } = useGetJobLogs({
    clusterName,
    jobId,
    ...(tableNameFromURL
      ? isSignedURL
        ? { table: tableNameFromURL }
        : { mV: tableNameFromURL }
      : {}),
    ...(signature ? { signature } : {}),
    ...(expires ? { expires } : {}),
  });

  const [isDownloadingLog, setIsDownloadingLog] = useState(false);
  const [logURL, setLogURL] = useState("");

  function downloadFile(url: string) {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    // a.target = "_blank";
    // a.download = "logs";
    // a.rel = "noreferrer";
    // document.body.appendChild(a);
    a.click();
    // document.body.removeChild(a);
  }

  async function handleDownloadLogFile() {
    setIsDownloadingLog(true);
    if (logURL) {
      downloadFile(logURL);
    } else {
      try {
        const res = await mutateGetJobLogURL();
        const url = res.data;
        downloadFile(url);
        setLogURL(url);
      } catch (error) {
        console.log(error);
        notification.error({
          message: intl.formatMessage({ id: "actions.downloadLogsError" }),
          description: error?.toString(),
        });
      }
    }
    setIsDownloadingLog(false);
  }

  return { isDownloadingLog, handleDownloadLogFile };
}

export default useDownloadLogs;
