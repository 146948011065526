import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { getClusterTokenFromCookies } from "utils/browserStorage";
import useSignedURL from "hooks/useSignedURL";
import useChidoriRoutes from "hooks/useChidoriRoutes";

function ProtectedRoute({
  redirectPath = "/landing",
  children,
}: PropsWithChildren<{ redirectPath: string }>): JSX.Element {
  const token = getClusterTokenFromCookies();

  const { isSignedURL } = useSignedURL();

  if (!token && !isSignedURL) {
    return <Navigate to={redirectPath} replace />;
  }
  return children as JSX.Element;
}

export default ProtectedRoute;
