import { message } from 'antd';
import { QueryServerKeys } from 'QueryServerKeys';
import { useQuery } from 'react-query';
import { getSparkMemoryMetrics } from 'services/sparkDriver';

export function useGetSparkMemoryMetrics({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.SPARK_DRIVER.GET_SPARK_DRIVER_MEMORY_USAGE, formatedRange],
    async () => {
      const res = await getSparkMemoryMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching spark driver memory metrics');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}
