import React, { useContext } from "react";
import { AppContext } from "contexts/AppContext";
import { ColumnSortEnum } from "types/types";

function useAntdSortColumns() {
  const { sortBy, sortOrder } = useContext(AppContext);

  function getSortOptions({ fieldName }: { fieldName: string }) {
    let antDSortOrder = {};
    if (sortBy && sortBy === fieldName && sortOrder) {
      antDSortOrder = {
        sortOrder:
          sortOrder === ColumnSortEnum.ascend
            ? "ascend"
            : sortOrder === ColumnSortEnum.descend
            ? "descend"
            : null,
      };
    }
    return { sorter: true, ...antDSortOrder };
  }

  return { getSortOptions };
}

export default useAntdSortColumns;
