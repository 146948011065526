import React from "react";
import "./StatusCellRenderer.scss";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { SparkJobStatuses } from "types/types";

export enum JobStatus {
  In_Queue = "In_Queue",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
  Cancelled = "Cancelled",
  Timed_Out = "Timed_Out",
}

const statusName = new Map<JobStatus, string>([
  [JobStatus.In_Queue, "In Queue"],
  [JobStatus.Running, "Running"],
  [JobStatus.Succeeded, "Succeeded"],
  [JobStatus.Failed, "Failed"],
  [JobStatus.Cancelled, "Cancelled"],
  [JobStatus.Timed_Out, "Timed Out"],
]);

export const statusTag: Record<SparkJobStatuses, JSX.Element> = {
  In_Queue: (
    <LoadingOutlined
      spin={true}
      className="status-cell-renderer-icon status-cell-renderer-icon--in-queue"
    />
  ),
  Running: (
    <LoadingOutlined
      spin={true}
      className="status-cell-renderer-icon status-cell-renderer-icon--running"
    />
  ),
  Succeeded: (
    <CheckCircleOutlined className="status-cell-renderer-icon status-cell-renderer-icon--succeeded" />
  ),
  Cancelled: (
    <CloseCircleOutlined className="status-cell-renderer-icon status-cell-renderer-icon--cancelled" />
  ),
  Failed: (
    <CloseCircleOutlined className="status-cell-renderer-icon status-cell-renderer-icon--failed" />
  ),
  Timed_Out: (
    <IssuesCloseOutlined className="status-cell-renderer-icon status-cell-renderer-icon--timed-out" />
  ),
} as const;

function StatusCellRenderer({ status }: { status: SparkJobStatuses }) {
  return (
    <article className="status-cell-renderer__wrapper">
      {statusTag[status]}
      {statusName.get(status)}
    </article>
  );
}

export default StatusCellRenderer;
