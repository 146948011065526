import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { convertTimestamp } from 'utils/date';
import { green, blue, gray } from '@ant-design/colors';

interface TimeSeriesProp {
  chartType: 'smooth' | 'straight' | 'stepline' | 'linestep' | 'monotoneCubic';
  chartTitle: string;
  yAxisFormatter: (val: number) => string;
  yAxisTitle: string;
  toolTipFormatter: (val: number) => string;
  seriesData: { name: string; data: number[] }[];
  timeData?: string[];
}

const TimeSeriesChart: React.FC<TimeSeriesProp> = ({
  chartType,
  chartTitle,
  yAxisFormatter,
  yAxisTitle,
  toolTipFormatter,
  seriesData,
  timeData,
}) => {
  const isSinglePoint = seriesData.every(series => series.data.length === 1);

  const [chartOptions] = useState<ApexOptions>({
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    colors: [blue[6], green[6]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: chartType,
      width: isSinglePoint ? 0 : 3,
    },
    markers: {
      size: isSinglePoint ? 8 : 0,
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    grid: {
      padding: {
        top: 60,
        bottom: 40,
        left: 60,
        right: 40,
      },
    },
    title: {
      text: chartTitle,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: gray[13],
      },
    },
    xaxis: {
      type: 'datetime',
      categories: timeData,
      labels: {
        formatter: (value: string) => {
          const isoString = new Date(parseInt(value)).toISOString();
          const { dateFormatted, timeFormatted } = convertTimestamp(isoString);
          return `${dateFormatted} ${timeFormatted}`;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: yAxisFormatter,
      },
      title: {
        text: yAxisTitle,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: toolTipFormatter,
      },
    },
    fill: {
      type: 'none',
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={seriesData}
      type="line"
      height={'650px'}
    />
  );
};

export default TimeSeriesChart;
