import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance, InputNumber, Select, Switch, Tooltip } from 'antd';
import { isValidEmail } from 'helpers/email';
import { FormattedMessage, useIntl } from 'react-intl';
import './Timeout.scss';
import '../ConfigForm.scss';
import React, { useState } from 'react';

function Timeout({ form }: { form: FormInstance }) {
  const intl = useIntl();
  const [enableTimeout, setEnableTimeout] = useState(
    form.getFieldValue('enableTimeout')
  );

  return (
    <section className="config-form__time-out">
      <div className="config-form__border-bottom config-form__section-switch">
        <Form.Item
          name="enableTimeout"
          label={
            <span className="config-form__section-headers-titles">
              <FormattedMessage id="settings.timeout.title" />
              <Tooltip
                title={intl.formatMessage({ id: 'settings.timeoutInfo' })}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked">
          <Switch onChange={setEnableTimeout} className="timeout__switch" />
        </Form.Item>
      </div>

      <span className="timeout__values">
        <Form.Item
          name="timeout"
          label={intl.formatMessage({ id: 'settings.timeout' })}
          labelCol={{ span: 6 }}
          labelAlign="left"
          wrapperCol={{ span: 18 }}
          rules={[
            {
              validator: (_, value) => {
                const hours = form.getFieldValue('hours') || 0;
                const minutes = form.getFieldValue('minutes') || 0;

                if (hours === 0 && minutes === 0 && enableTimeout) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({
                        id: 'settings.timeoutValidationError',
                      })
                    )
                  );
                }

                return Promise.resolve();
              },
            },
          ]}>
          <div className="timeout__inputs">
            <Form.Item name="hours">
              <InputNumber
                disabled={!enableTimeout}
                min={0}
                addonAfter={intl.formatMessage({ id: 'settings.hours' })}
              />
            </Form.Item>

            <Form.Item name="minutes">
              <InputNumber
                disabled={!enableTimeout}
                min={0}
                addonAfter={intl.formatMessage({ id: 'settings.minutes' })}
              />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item
          label="Warning Emails"
          name="warningEmails"
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[
            {
              validator: (_, value) => {
                if (enableTimeout && (!value || value.length === 0)) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({ id: 'settigns.warning.emptyEmail' })
                    )
                  );
                }

                if (!enableTimeout && !value) {
                  return Promise.resolve();
                }

                const allValid = value?.every((email: string) =>
                  isValidEmail(email)
                );

                return allValid
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        intl.formatMessage({
                          id: 'settings.warning.validEmails',
                        })
                      )
                    );
              },
            },
          ]}>
          <Select
            disabled={!enableTimeout}
            mode="tags"
            className="timeout__emails-select"
            placeholder="email@example.com"
            tokenSeparators={[',']}
          />
        </Form.Item>

        <Form.Item
          name="killOnTimeout"
          valuePropName="checked"
          label={<FormattedMessage id="settings.jobsTermination" />}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 6 }}>
          <Switch disabled={!enableTimeout} className="config-form__switch" />
        </Form.Item>
      </span>
    </section>
  );
}

export default Timeout;
