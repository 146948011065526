import React from 'react';
import './DateCellRenderer.scss';
import { convertTimestamp } from 'utils/date';

function DateCellRenderer({ originalValue }: { originalValue: string }) {
  const { dateFormatted, timeFormatted } = convertTimestamp(originalValue);

  return (
    <div className="date-cell-renderer__creation-time-column-wrapper">
      <label className="date-cell-renderer__creation-time-column-date">
        {dateFormatted}
        <br />
        {timeFormatted}
      </label>
    </div>
  );
}

export default DateCellRenderer;
