import Cookies from "js-cookie";


export function getClusterTokenFromCookies() {
  const clusterName = window.location.pathname.split("/cluster/")[1].split("/")[0];
  return Cookies.get(clusterName);
}

export function setToken({
  clusterName,
  token,
}: {
  clusterName: string;
  token: string;
}) {
  const path =  `/cluster/${clusterName}`;
  Cookies.set(clusterName, token, {
    path,
  });
}
