import { useContext } from "react";
import { AppContext } from "contexts/AppContext";
import { TablePaginationConfig } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/es/table/interface";
import { ColumnSort, JobsResponseItem } from "types/types";

function useAntdTableChange() {
  const { setSortBy, setSortOrder } = useContext(AppContext);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<JobsResponseItem> | SorterResult<JobsResponseItem>[],
    extra: TableCurrentDataSource<JobsResponseItem>
  ) {
    const isSortAppliedOnMultipleColumns = Array.isArray(sorter);
    if (!isSortAppliedOnMultipleColumns) {
      if (sorter.columnKey && sorter?.order) {
        setSortBy(sorter.columnKey as string);
        setSortOrder(sorter.order as ColumnSort);
      } else {
        setSortBy(null);
        setSortOrder(null);
      }
    }
  }
  return { onChange };
}

export default useAntdTableChange;
