import { AutoComplete } from 'antd';
import { useState } from 'react';
import './SearchableAutoComplete.scss';

interface SearchableAutoCompleteProps {
  text: string;
  placeholder: string;
  handleInputChange: (value: string) => void;
  allOptions: { value: string }[];
}

function SearchableAutoComplete({
  text,
  placeholder,
  handleInputChange,
  allOptions,
}: SearchableAutoCompleteProps) {
  const [filteredOptions, setFilteredOptions] = useState<{ value: string }[]>(
    []
  );

  const handleSearch = (searchText: string) => {
    if (!searchText) {
      setFilteredOptions(allOptions);
    } else {
      const filtered = allOptions.filter((option: { value: string }) =>
        option.value.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  const handleFocus = () => {
    setFilteredOptions(allOptions);
  };

  return (
    <AutoComplete
      className="searchable-autocomplete"
      value={text}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onSearch={handleSearch}
      options={filteredOptions}
      placeholder={placeholder}
    />
  );
}

export default SearchableAutoComplete;
