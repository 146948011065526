import React, { useContext } from "react";
import "./DownloadLogsAction.scss";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { AppContext } from "contexts/AppContext";
import useDownloadLogs from "hooks/useDownloadLogs";
import { useIntl } from "react-intl";
import { SizeType } from "antd/es/config-provider/SizeContext";

type DownloadLogsActionProps = {
  jobId: string;
  isDisabled: boolean;
  showText?: boolean;
  btnSize?: SizeType;
};
function DownloadLogsAction({
  jobId,
  isDisabled = false,
  showText = true,
  btnSize,
}: DownloadLogsActionProps) {
  const intl = useIntl();
  const { clusterName } = useContext(AppContext);

  const { isDownloadingLog, handleDownloadLogFile } = useDownloadLogs({
    clusterName,
    jobId,
  });

  return (
    <Tooltip
      title={intl.formatMessage({
        id: "actions.downloadLogs",
      })}
    >
      <Button
        type="ghost"
        className="download-logs-action__btn"
        onClick={handleDownloadLogFile}
        loading={isDownloadingLog}
        icon={<DownloadOutlined />}
        size={btnSize}
        disabled={isDisabled}
      >
        {showText && intl.formatMessage({ id: "actions.downloadLogs" })}
      </Button>
    </Tooltip>
  );
}

export default DownloadLogsAction;
