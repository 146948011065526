import React, { useContext } from "react";
import "./UserProfile.scss";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { FormattedMessage } from "react-intl";
import { AppContext } from "contexts/AppContext";
import { useLogOutUser } from "hooks/user";
import useSignedURL from "hooks/useSignedURL";

function UserProfile() {
  const { clusterName } = useContext(AppContext);
  const { logOutUser } = useLogOutUser({ clusterName });

  const { isSignedURL } = useSignedURL();

  function handleLogout() {
    if (!isSignedURL) logOutUser();
  }

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <span>
          <LogoutOutlined className="user-profile__menu-item-icon" />
          <FormattedMessage id="header.navBar.logout" />
        </span>
      ),
      disabled: isSignedURL,
      onClick: handleLogout,
    },
  ];

  return (
    <div className="user-profile__wrapper">
      <Dropdown menu={{ items }} autoAdjustOverflow trigger={["click"]}>
        <Button
          className="user-profile__button"
          type="text"
          icon={<UserOutlined />}
        >
          <FormattedMessage id="sidebarMenu.account" />
        </Button>
      </Dropdown>
    </div>
  );
}

export default UserProfile;
