import axios, { AxiosError } from "axios";
import { API_URL } from "utils/http";

export async function SignIn({
  clusterName,
  basicToken,
}: {
  clusterName: string;
  basicToken: string;
}) {
  try {
    const res = await axios.post<{ token: string }>(
      `${API_URL}/auth?cluster_name=${clusterName}`,
      null,
      {
        headers: {
          Accept: "*/*",
          Authorization: `Basic ${basicToken}`,
        },
      }
    );
    return res;
  } catch (error) {
    const err = error as AxiosError;
    throw err;
  }
}


