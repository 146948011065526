import { message } from 'antd';
import { QueryServerKeys } from 'QueryServerKeys';
import { useQuery } from 'react-query';
import { getRunningJobsMetrics } from 'services/numberOfRunningJobs';

export function useGetNumberOfRunningJobs({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [
      QueryServerKeys.NUMBER_OF_RUNNING_JOBS.GET_NUMBER_OF_RUNNING_JOBS,
      formatedRange,
    ],
    async () => {
      const res = await getRunningJobsMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching number of running jobs ');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}
