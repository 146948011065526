import React from "react";
import { Typography } from "antd";
import { ReactComponent as NotFoundIcon } from "assets/icons/not-found.svg";
import { FormattedMessage } from "react-intl";

const { Title } = Typography;

function WrongRoute() {
  return (
    <div>
      <NotFoundIcon />

      <Title level={4}>
        <FormattedMessage id="wrongRoute.title" />
      </Title>
      <Title level={5}>
        <FormattedMessage id="wrongRoute.subTitle" />
      </Title>
    </div>
  );
}

export default WrongRoute;
