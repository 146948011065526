import React from "react";
import "./HoverActions.scss";
import DownloadLogsAction from "components/DownloadLogsAction/DownloadLogsAction";
import SignedURLButton from "components/SignedURLButton/SignedURLButton";
import SparkLogButton from "components/SparkLogButton/SparkLogButton";
import { SparkJobStatuses } from "types/types";

type HoverActionsProps = {
  tableName: string;
  sparkJobID: string;
  isDownloadLogsBtnDisabled: boolean;
  clusterUrl: string;
  sparkJobStatus: SparkJobStatuses;
  sparkAppID: string;
  clusterName: string;
  schemaName: string;
  clusterPrefix: string;
};
function HoverActions({
  tableName,
  sparkJobID,
  isDownloadLogsBtnDisabled,
  clusterUrl,
  sparkJobStatus,
  sparkAppID,
  clusterName,
  schemaName,
  clusterPrefix,
}: HoverActionsProps) {
  return (
    <>
      <article className="hover-action__wrapper">
        <span className="hover-action__btn-wrapper--hide">
          <DownloadLogsAction
            jobId={sparkJobID}
            showText={false}
            btnSize="middle"
            isDisabled={isDownloadLogsBtnDisabled}
            key={sparkJobID}
          />
        </span>
        <span className="hover-action__btn-wrapper--hide">
          <SparkLogButton
            clusterUrl={clusterUrl}
            jobStatus={sparkJobStatus}
            sparkAppID={sparkAppID}
            showText={false}
            btnSize="middle"
          />
        </span>
        <span className="hover-action__btn-wrapper--hide">
          <SignedURLButton
            tableName={tableName}
            clusterName={clusterName}
            schema={schemaName}
            jobId={sparkJobID}
            prefix={clusterPrefix}
            showText={false}
            btnSize="middle"
          />
        </span>
      </article>
    </>
  );
}

export default HoverActions;
