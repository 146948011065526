import React from 'react';
import './ConnectPageLayout.scss'; 
import Connections from '../Connections/Connections';
import Introduction from '../ConnectTitle/ConnectTitle';
import Header from 'components/Header/Header';
import { FormattedMessage } from 'react-intl';
import { headerTypesEnum } from 'types/types';

const ConnectPageLayout: React.FC = () => (
  <section className="connect-page-layout">
    <Header headerType={headerTypesEnum.CONNECT}>
        <FormattedMessage id="chidoriConnect.integration" />
    </Header>
    <Introduction />
    <section className="connect-content-wrapper">
        <Connections />
    </section>
  </section>
);

export default ConnectPageLayout;
