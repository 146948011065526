import React from 'react';
import { Space, Table } from 'antd';
import type { TableProps } from 'antd';
import { ConnectConfig } from 'types/connect.type';
import { useIntl } from 'react-intl';

interface DataType {
  key: string;
  property: string;
  value: string | number | boolean | string[];
}

const columns: TableProps<DataType>['columns'] = [
  {
    dataIndex: 'property',
    key: 'property',
  },
  {
    dataIndex: 'value',
    key: 'value',
    render: (text, record) =>
      Array.isArray(text) ? text.join(', ') : text?.toString(),
  },
];

interface ShowConfigDetailsProps {
  connectConfig: ConnectConfig;
}

const ShowConfigDetails: React.FC<ShowConfigDetailsProps> = ({ connectConfig }) => {
  const intl = useIntl();
  const data: DataType[] =
    [
      {
        key: '2',
        property: intl.formatMessage({ id: 'chidoriConnect.driverCores' }),
        value: connectConfig.SPARK_DRIVER_CORES,
      },
      {
        key: '3',
        property: intl.formatMessage({ id: 'chidoriConnect.driverMemory' }),
        value: connectConfig.SPARK_DRIVER_MEMORY,
      },
      {
        key: '4',
        property: intl.formatMessage({ id: 'chidoriConnect.executorCores' }),
        value: connectConfig.SPARK_EXECUTOR_CORES,
      },
      {
        key: '5',
        property: intl.formatMessage({ id: 'chidoriConnect.executorMemory' }),
        value: connectConfig.SPARK_EXECUTOR_MEMORY,
      },
      {
        key: '6',
        property: intl.formatMessage({ id: 'chidoriConnect.tenantName' }),
        value: connectConfig.TENANT_NAME.split('/')[1],
      },
      {
        key: '7',
        property: intl.formatMessage({ id: 'chidoriConnect.allowedSchemas' }),
        value: connectConfig.ALLOWED_SCHEMAS.join(', '),
      },
    ]


  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default ShowConfigDetails;
