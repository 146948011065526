import { Property } from 'types/cluster.type';

export function createSparkHistoryServerLink(clusterUrl: string) {
  return `${clusterUrl}/applications`;
}

export function convertRecordToProperties(
  record: Record<string, string>
): Property[] {
  let jobCounter = 1;

  return Object.entries(record).map(([key, value]) => {
    return {
      key: (jobCounter++).toString(),
      jobKey: key,
      value: value,
    };
  });
}
