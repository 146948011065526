import React from 'react';

import { ReactComponent as PythonIcon } from 'assets/icons/python.svg';
import { ReactComponent as SparkSQLIcon } from 'assets/icons/postgeSQL.svg';
import { ReactComponent as RIcon } from 'assets/icons/R.svg';
import { ReactComponent as ScalaIcon } from 'assets/icons/Scala.svg';
import { MVLanguageEnum } from 'types/types';
import './LanguageCellRenderer.scss';

const languageIconMap: Record<
  MVLanguageEnum,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  'Spark Python': PythonIcon,
  'Spark SQL/PostgreSQL': SparkSQLIcon,
  'Spark R': RIcon,
  'Spark Scala': ScalaIcon,
};

export default function LanguageCellRenderer({
  MVLanguage,
}: {
  MVLanguage: MVLanguageEnum;
}) {
  const IconComponent = languageIconMap[MVLanguage];

  return (
    <article className="mv-language-renderer">
      {IconComponent ? <IconComponent className="icon" /> : null}
      <span>{MVLanguage}</span>
    </article>
  );
}
