import React, { PropsWithChildren, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import { IntlProvider } from "react-intl";

function LocaleProvider({ children }: PropsWithChildren) {
  const { currentLocale } = useContext(AppContext);
  const browserLanguage = navigator.language;

  return (
    <IntlProvider
      locale={browserLanguage}
      messages={currentLocale}
      defaultLocale="en-US"
    >
      {children}
    </IntlProvider>
  );
}

export default LocaleProvider;
