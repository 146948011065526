import React from 'react';
import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import { useEnableChidoriConnect } from 'hooks/connect';
import { ConnectConfig } from 'types/connect.type';
import { useIntl } from 'react-intl';
import "./ConnectConfigForm.scss"

interface ModalProps {
    setIsModalVisible: (visible: boolean) => void;
}

const ConnectConfigForm: React.FC<ModalProps> = ({ setIsModalVisible }) => {
    const [form] = Form.useForm();
    const { mutateAsync: mutateEnableChidoriConnect, isLoading } = useEnableChidoriConnect();
    const intl = useIntl();
    
    async function handleOk() {
        try {
            const values = await form.validateFields();
            const config: ConnectConfig = {
                IS_CHIDORI_CONNECT_ENABLED: false,
                SPARK_DRIVER_MEMORY: values.DriverMemory,
                SPARK_DRIVER_CORES: values.DriverCores,
                SPARK_EXECUTOR_MEMORY: values.ExecutorMemory,
                SPARK_EXECUTOR_CORES: values.ExecutorCores,
                TENANT_NAME: `Tenants/${values.TenantName}/source`,
                ALLOWED_SCHEMAS: values.AllowedSchemas,
                IS_TRANSITIONING: false,
            };
    
            await mutateEnableChidoriConnect({ config });
            message.success(intl.formatMessage({ id: "chidoriConnect.enableSuccess" }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "chidoriConnect.enableFail" }));
        } finally {
            setIsModalVisible(false);  
        }
    }
    
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Form
            form={form}
            initialValues={{ prefix: '86' }}
            className="connect-form"
            scrollToFirstError
            labelCol={{ span: 9 }}
            labelAlign='left'
            wrapperCol={{ span: 15 }}
            onFinish={handleOk}
        >
            <Form.Item
                name="DriverMemory"
                label={intl.formatMessage({ id: "chidoriConnect.driverMemory" })}
                rules={[
                    { required: true, message: intl.formatMessage({ id: "chidoriConnect.error.requiredMemory" }) },
                    { type: 'number', min: 1, message: intl.formatMessage({ id: "chidoriConnect.error.minMemory" }) },
                ]}
            >
                <InputNumber min={1} className="connect-form__input" placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.driverMemory" })} />
            </Form.Item>

            <Form.Item
                name="DriverCores"
                label={intl.formatMessage({ id: "chidoriConnect.driverCores" })}
                rules={[
                    { required: true, message: intl.formatMessage({ id: "chidoriConnect.error.requiredCores" }) },
                    { type: 'number', min: 1, message: intl.formatMessage({ id: "chidoriConnect.error.minCores" }) },
                ]}
            >
                <InputNumber min={1} className="connect-form__input" placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.driverCores" })} />
            </Form.Item>

            <Form.Item
                name="ExecutorMemory"
                label={intl.formatMessage({ id: "chidoriConnect.executorMemory" })}
                rules={[
                    { required: true, message: intl.formatMessage({ id: "chidoriConnect.error.requiredMemory" }) },
                    { type: 'number', min: 1, message: intl.formatMessage({ id: "chidoriConnect.error.minMemory" }) },
                ]}
            >
                <InputNumber min={1} className="connect-form__input" placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.executorMemory" })} />
            </Form.Item>

            <Form.Item
                name="ExecutorCores"
                label={intl.formatMessage({ id: "chidoriConnect.executorCores" })}
                rules={[
                    { required: true, message: intl.formatMessage({ id: "chidoriConnect.error.requiredCores" }) },
                    { type: 'number', min: 1, message: intl.formatMessage({ id: "chidoriConnect.error.minCores" }) },
                ]}
            >
                <InputNumber min={1} className="connect-form__input" placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.executorCores" })} />
            </Form.Item>

            <Form.Item
                name="TenantName"
                label={intl.formatMessage({ id: "chidoriConnect.tenantName" })}
                rules={[{ required: true, message: intl.formatMessage({ id: "chidoriConnect.error.selectTenant" }) }]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.selectTenant" })}
                    className="connect-form__input"
                />
            </Form.Item>

            <Form.Item
                name="AllowedSchemas"
                label={intl.formatMessage({ id: "chidoriConnect.allowedSchemas" })}
                rules={[{ required: true, message: intl.formatMessage({ id: "chidoriConnect.error.selectSchema" }) }]}
            >
                <Select
                    className='connect-form__allowed-schema connect-form__input'
                    mode="tags"
                    placeholder={intl.formatMessage({ id: "chidoriConnect.placeholder.enterSchemas" })}
                    tokenSeparators={[',']}
                />
            </Form.Item>

            <div className="connect-form__buttons">
                <Button key="cancel" onClick={handleCancel}>
                    {intl.formatMessage({ id: "chidoriConnect.button.cancel" })}
                </Button>
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleOk}
                    loading={isLoading}
                    className="connect-form__submit"
                >
                    {intl.formatMessage({ id: "chidoriConnect.button.connect" })}
                </Button>
            </div>
        </Form>
    );
};

export default ConnectConfigForm;
