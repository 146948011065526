import { queryClient } from "index";
import { QueryServerKeys } from "QueryServerKeys";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { disableChidoriConnect, enableChidoriConnect, getAuthFile, getConnectCanRun, getConnectConfig } from "services/connect";
import { ConnectConfig } from "types/connect.type";

const defaultRefetchInterval = 2 * (60 * 1000); 
const quickRefetchInterval = 40 * 1000;

export function useGetConnectConfig() {
  const [refetchInterval, setRefetchInterval] = useState(defaultRefetchInterval);

  const queryOptions = useQuery(
    QueryServerKeys.CONNECT.GET_CONNECT_CONFIGURATION,
    async () => {
      const res = await getConnectConfig();
      return res?.data;
    },
    {
      refetchInterval: refetchInterval,
      staleTime: refetchInterval,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        if (data?.IS_TRANSITIONING) {
          setRefetchInterval(quickRefetchInterval); 
        } else {
          setRefetchInterval(defaultRefetchInterval); 
        }
      },
    }
  );

  return queryOptions;
}



export function useGetConnectCanRun() {

  const queryOptions = useQuery(
    QueryServerKeys.CONNECT.GET_CONNECT_CAN_RUN,
    async () => {
      const res = await getConnectCanRun();
      return res?.data;
    },
  );

  return queryOptions;
}

export function useEnableChidoriConnect() {
  const queryOptions = useMutation(
    async ({
      config,
    }: {
      config: ConnectConfig;
    }) => {
      return await enableChidoriConnect({ config });
    },
    {
      mutationKey: [QueryServerKeys.CONNECT.ENABLE_CHIDORI_CONNECT],
      async onSuccess() {
        queryClient.invalidateQueries(QueryServerKeys.CONNECT.GET_CONNECT_CONFIGURATION);
      },
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

export function useDisableChidoriConnect() {
  const queryOptions = useMutation(
    async () => {
      return await disableChidoriConnect();
    },
    {
      mutationKey: [QueryServerKeys.CONNECT.DISABLE_CHIDORI_CONNECT],
      async onSuccess() {
        queryClient.invalidateQueries(QueryServerKeys.CONNECT.GET_CONNECT_CONFIGURATION);
      },
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

export function useAuthFile() {
  const queryOptions = useQuery(
    QueryServerKeys.CONNECT.GET_AUTH_FILE,
    async () => {
      const res = await getAuthFile();
      return res?.data;
    },
    {
      enabled:false
    }
  );

  return queryOptions;
}

