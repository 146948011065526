import ProtectedRoute from 'AppRoutes/ProtectedRoute';
import React, { useContext } from 'react';
import { AVAILABLE_PATHS, pathKeys } from 'types/types';
import App from 'App';
import { AppContext } from 'contexts/AppContext';

export default function SignInPage() {
  const { clusterName } = useContext(AppContext);
  
  return (
    <ProtectedRoute
      redirectPath={(AVAILABLE_PATHS.get(pathKeys.SIGN_IN) as string).replace(
        ':clusterName',
        clusterName || window.location.pathname.split('/cluster/')[1]
      )}>
      <App />
    </ProtectedRoute>
  );
}
