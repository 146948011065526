import Header from 'components/Header/Header';
import JobsTableDetails from 'components/JobsTable/JobsTableDetails/JobsTableDetails';
import StatusStatistics from 'components/JobsTable/StatusStatistics/StatusStatistics';
import { AppContext } from 'contexts/AppContext';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { headerTypesEnum } from 'types/types';

export default function JobsTableDetailsPage() {
  const { clusterName } = useContext(AppContext);

  return (
    <>
      <Header headerType={headerTypesEnum.JOBS}>
        <FormattedMessage id="header.jobsOfCluster" values={{ clusterName }} />
      </Header>
      <StatusStatistics />
      <JobsTableDetails />
    </>
  );
}
