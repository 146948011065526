import Header from 'components/Header/Header';
import MetricsLayout from 'components/MetricsLayout/MetricsLayout';
import { FormattedMessage } from 'react-intl';
import { headerTypesEnum } from 'types/types';

export default function MetricsPage() {
  return (
    <>
      <Header headerType={headerTypesEnum.METRICS}>
        <FormattedMessage id="sidebarMenu.metrics" />
      </Header>
      <MetricsLayout />
    </>
  );
}
