import React from "react";
import { useSearchParams } from "react-router-dom";

function useSignedURL() {
  const [searchParams] = useSearchParams();
  const signature = searchParams.get("signature");
  const expires = searchParams.get("expires");
  const prefix = searchParams.get("prefix");
  const schemaFromSignedURL = searchParams.get("schema");
  const isSignedURL = !!(signature && expires);
  return { isSignedURL, signature, expires, prefix, schemaFromSignedURL };
}

export default useSignedURL;
