import React, { useContext } from "react";
import "./LanguageChanger.scss";
import { Select } from "antd";
import { languages } from "enums";
import { AppContext } from "contexts/AppContext";
import { GlobalOutlined } from "@ant-design/icons";

function LanguageChanger() {
  const { handleChangeLanguage, selectedLanguage } = useContext(AppContext);

  return (
    <Select
      className="language-changer__wrapper"
      popupClassName="language-changer__popup"
      defaultValue={languages.English}
      value={selectedLanguage}
      options={Object.keys(languages).map((lang: string) => ({
        value: languages[lang],
        label: (
          <li className="language-changer__language-item">
            <GlobalOutlined className="language-changer__language-icon" />
            {lang === "Japanese" ? "日本語" : lang}
          </li>
        ),
      }))}
      onChange={(val) => {
        handleChangeLanguage(val);
      }}
    />
  );
}

export default LanguageChanger;
