import axios, { AxiosResponse } from "axios";
import { ConnectCanRun, ConnectConfig } from "types/connect.type";
import { API_URL } from "utils/http";


export async function getConnectConfig() {
    try {
        const res = await axios.get<ConnectConfig>(
            `${API_URL}/chidori_connect/config`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getConnectCanRun() {
    try {
        const res = await axios.get<ConnectCanRun>(
            `${API_URL}/chidori_connect/can-run`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getAuthFile() {
    try {
        const res = await axios.get<string>(
            `${API_URL}/chidori_connect/file-auth`);
        return res;
    } catch (error) {
        throw error;
    }
}

export async function enableChidoriConnect({
    config,
}: {
    config: ConnectConfig;
}) {
    try {
        const res = await axios.post(
            `${API_URL}/chidori_connect/enable`,
            config
        );
        return res;
    } catch (error) {
        throw error;
    }
}

export async function disableChidoriConnect() {
    try {
      const res = await axios.post(
        `${API_URL}/chidori_connect/disable` // No config passed here
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
  