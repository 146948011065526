import {
    DownloadOutlined,
} from '@ant-design/icons';

import './ConnectionSteps.scss'; // Import the CSS file

import CodeSnippet from './CodeSnippet';
import { useAuthFile } from 'hooks/connect';
import { Button, message } from 'antd';
import { useIntl } from 'react-intl';

const CONNECT_PYTHON_WHEEL_URL = 'https://storage.googleapis.com/chidori-connect/chidori-1.0.0-py3-none-any.whl';

function ConnectionSteps() {
    const { isFetching: isAuthDataFetching, refetch: refetchAuthData } = useAuthFile();
    const intl = useIntl();

    const handleDownloadAuth = async () => {
        try {
            const { data: authData } = await refetchAuthData();
            const jsonResponse = authData;
            const blob = new Blob([JSON.stringify(jsonResponse, null, 2)], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Auth.txt';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error fetching or downloading auth data:', error);
            message.error(intl.formatMessage({ id: 'chidoriConnect.error.downloadFailed' }));
        }
    };

    const handleDownloadPython = () => {
        const link = document.createElement('a');
        link.href = CONNECT_PYTHON_WHEEL_URL;
        link.download = 'chidori-1.0.0-py3-none-any.whl'
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <section className="connect-steps-modal-content__wrapper">
            <p>{intl.formatMessage({ id: 'chidoriConnect.followSteps' })}</p>
            <ol>
                <li>
                    <h3 className='steps-titles'>{intl.formatMessage({ id: 'chidoriConnect.downloadPythonWheel' })}</h3>
                    <p>
                        {intl.formatMessage({ id: 'chidoriConnect.pythonWheelInstruction' })}
                    </p>
                    <Button
                        className="download-button"
                        type="link"
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadPython}
                    >
                        {intl.formatMessage({ id: 'chidoriConnect.downloadPythonButton' })}
                    </Button>
                </li>

                <li>
                    <h3 className='steps-titles'>{intl.formatMessage({ id: 'chidoriConnect.authorizeConnection' })}</h3>
                    <p>
                        {intl.formatMessage({ id: 'chidoriConnect.authFileInstruction' })} <br />
                        {intl.formatMessage({ id: 'chidoriConnect.authFileNote' })}
                    </p>
                    <Button
                        className="download-button"
                        type="link"
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadAuth}
                        loading={isAuthDataFetching}
                    >
                        {intl.formatMessage({ id: 'chidoriConnect.downloadAuthButton' })}
                    </Button>
                </li>

                <li>
                    <h3 className='steps-titles'>{intl.formatMessage({ id: 'chidoriConnect.startWithCode' })}</h3>
                    <p>
                        {intl.formatMessage({ id: 'chidoriConnect.codeInstruction' })}
                    </p>
                </li>
                <CodeSnippet />
            </ol>

            <label className="cluster-data-agent-edit__hint">
                {intl.formatMessage({ id: 'chidoriConnect.moreInfo' })}{' '}
                <a
                    href="https://docs.incorta.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({ id: 'chidoriConnect.documentation' })}
                </a>{' '}
                {intl.formatMessage({ id: 'chidoriConnect.onConnect' })}
            </label>
        </section>
    );
}

export default ConnectionSteps;
